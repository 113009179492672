var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"log-center-section"},[_c('Breadcrumb'),_c('div',{staticClass:"card-log-center"},[_c('div',{staticClass:"card-header-log-center",style:({
        background: _vm.active ? '#E8F5E9' : '#FFEBEE',
        color: _vm.active ? '#1B5E20' : '#B71C1C'
      })},[_vm._v(" "+_vm._s(_vm.active ? _vm.$t('LogCenter.SelfHostedRunning') : _vm.$t('LogCenter.SelfHostedOffline'))+" ")]),_c('div',{staticClass:"card-log-center-title"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Log Center'))+" ")]),_c('div',{staticClass:"card-title-actions"},[_c('b-button',{staticClass:"btn-title-action",on:{"click":_vm.refreshData}},[_c('ReloadIcon',{staticClass:"reload-icon"}),_vm._v(" "+_vm._s(_vm.$t('IntegrationPage.Refresh'))+" ")],1),_c('PeriodFilterComponent',{ref:"periodFilter",attrs:{"position":"left","options":_vm.periodFilterOptions,"defaultDate":_vm.periodFilterDefaultDate},on:{"change":_vm.changeFilterDays}})],1)]),_c('div',{staticClass:"graph-buttons-resources"},[_c('button',{class:['btn-graph-resources', _vm.showPipelines ? 'btn-active' : 'btn-disabled'],attrs:{"type":"button"},on:{"click":function () {
            _vm.showPipelines = true;
            this$1.getIntegrationLog();
          }}},[_vm._v(" Pipeline ")]),_c('button',{class:['btn-graph-resources', _vm.showPipelines ? 'btn-disabled' : 'btn-active'],attrs:{"type":"button"},on:{"click":function () {
            _vm.showPipelines = false;
            this$1.getServiceLog();
          }}},[_vm._v(" SHIR ")])]),_c('b-col',{attrs:{"cols":"12"}},[(_vm.showPipelines)?_c('integration-log',{attrs:{"listagem":_vm.listagemIntegration,"id":_vm.$route.query.id},on:{"atualizaListagemIntegration":_vm.getIntegrationLog}}):_c('service-log',{attrs:{"listagem":_vm.listagemLog,"id":_vm.$route.query.id}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }