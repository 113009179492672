<template>
  <div class="card-service-log">
    <div>
      <table-default
        :rows="listagemLog"
        :fields="fields"
        :stickyHeader="'1440px'"
        :striped="true"
        :searchDefault="id"
      >
        <template v-slot:cell(created_at)="data">
          <div>
            {{ getFormatedDate(data.item.created_at) }}
          </div>
        </template>

        <template v-slot:cell(message)="data">
          <div class="add-ellipsis">
            <span :id="`tooltip-log-message-${data.index}`">
              {{ data.item.message }}
            </span>
          </div>

          <b-tooltip
            custom-class="tooltip-info-log-center"
            :target="`tooltip-log-message-${data.index}`"
            triggers="hover blur"
            positioning="top"
            placement="top"
            boundary-padding="0"
          >
            <div class="info-tooltip-log-center">
              {{ data.item.message }}
            </div>
          </b-tooltip>
        </template>
      </table-default>
    </div>
  </div>
</template>

<script>
  import {
    BRow,
    BBadge,
    BPagination,
    BSidebar,
    VBToggle,
    BButton,
    BSpinner,
    BCardText,
    BTooltip
  } from 'bootstrap-vue';
  import Ripple from 'vue-ripple-directive';
  import TableDefault from '@core/components/table-default/TableDefault.vue';
  import moment from 'moment';

  export default {
    components: {
      BRow,
      BBadge,
      BPagination,
      BSidebar,
      BButton,
      BSpinner,
      TableDefault,
      BCardText,
      BTooltip
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple
    },
    data() {
      return {
        currentSite: JSON.parse(localStorage.getItem('currentSite')),
        pageLength: 10,
        dir: false,
        connectionId: '',
        fields: [
          {
            label: 'Id',
            key: 'id',
            sortable: true
          },
          {
            label: 'IntegrationPage.Message',
            key: 'message',
            sortable: true,
            thStyle: { width: '68%' }
          },
          {
            label: 'ProductionProgress.Date',
            key: 'created_at',
            sortable: true,
            thStyle: { width: '22%' }
          }
        ],
        rows: [],
        searchTerm: '',
        refreshing: false,
        message: '',
        intervalFunction: 0,
        listagemLog: []
      };
    },
    props: {
      listagem: {
        type: Number
      },
      id: {
        type: String,
        default: ''
      }
    },
    watch: {
      listagem: function (newVal, oldVal) {
        this.listagemLog = newVal;
      }
    },
    destroyed() {
      clearInterval(this.intervalFunction);
    },
    methods: {
      getFormatedDate(date) {
        if (date) {
          let language = localStorage.getItem('language');

          if (language == 'pt') return moment(date).format('DD-MM-YYYY HH:mm:ss');
          else return moment(date).format('MM-DD-YYYY HH:mm:ss');
        }

        return '';
      },
      openMessageModal(message) {
        this.message = message;
      }
    }
  };
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-good-table.scss';
  @import '~@core/scss/vue/libs/vue-sweetalert.scss';

  .card-service-log {
    .overlay-mensage {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-direction: column;
    }

    .pagination-table {
      margin-bottom: 15px !important;
    }

    .add-ellipsis {
      white-space: nowrap;
      max-width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
</style>
